export default (): string | undefined => {
  const found: string[] = [];

  if (typeof navigator !== 'undefined') {
    if (navigator.language && !found.includes(navigator.language)) {
      found.push(navigator.language);
    }

    if (navigator.languages) {
      // chrome only; not an array, so can't use .push.apply instead of iterating
      for (let i = 0; i < navigator.languages.length; i++) {
        if (!found.includes(navigator.languages[i]))
          found.push(navigator.languages[i]);
      }
    }
  }
  return found.length > 0 ? found[0] : undefined;
};
