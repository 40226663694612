import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';

// prop-types
interface Props {
  isOpen: boolean;
  onClose(value?: boolean): void;
}

const About = ({ isOpen, onClose }: Props) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="mt-20 flex items-center justify-center py-4 px-8 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-semibold leading-6 text-gray-900 dark:text-gray-400"
                >
                  About
                </Dialog.Title>
                <div
                  className="absolute top-2 right-2 cursor-pointer text-gray-400 hover:text-gray-500 dark:text-gray-700"
                  onClick={() => onClose()}
                >
                  <XCircleIcon className="h-6 w-6" />
                </div>
                <div className="mx-auto max-w-5xl place-items-center pt-6 text-gray-900 dark:text-gray-400">
                  <p className="text-lg">
                    Tesla web browser full screen display. by John.
                  </p>
                  <p className="pt-8 text-base text-gray-400 dark:text-gray-700">
                    © S3XY.app
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default About;
