import React from 'react';

// prop-types
interface Props {
  icon: string;
  name: string;
  url: string;
}

const Card = ({ icon, name, url }: Props) => {
  return (
    <a
      href={url}
      className="relative block h-28 w-full overflow-hidden rounded-md border-[3px] border-white ring ring-gray-200 hover:ring-blue-600 dark:border-gray-900 dark:ring-gray-700"
    >
      <img
        alt={name}
        src={icon}
        className="h-full w-full bg-gray-200 object-cover dark:bg-gray-800"
      />
      <div className="absolute left-0 bottom-0 w-full bg-gray-600 bg-opacity-50 px-3 py-1 text-white dark:bg-gray-900 dark:bg-opacity-50 dark:text-gray-300">
        <h3
          className="w-full truncate text-lg font-medium"
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </div>
    </a>
  );
};

export default Card;
