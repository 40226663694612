import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import AddressBar from '../components/AddressBar';
import Tiles from '../components/Tiles';
import useImageArchive from '../hooks/useImageArchive';
import FloatingMenu from '../components/FloatingMenu';
import FullScreen from '../components/FullScreen';

const HomePage = () => {
  useImageArchive();
  const navigate = useNavigate();
  const location = useLocation();
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (location.search.startsWith('?www')) {
      setIsFullScreen(true);
      navigate('/', { replace: true });
    }
  }, [location, navigate]);

  return (
    <>
      <div className="absolute inset-0 z-30 h-full w-full overflow-auto">
        <Header />
        <AddressBar />
        <Tiles />
        <FullScreen hide={isFullScreen} />
      </div>
      <FloatingMenu />
    </>
  );
};

export default HomePage;
