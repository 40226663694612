import React from 'react';

// prop-types
interface Props {
  hide: boolean;
}

const FullScreen = ({ hide }: Props) => {
  return !hide ? (
    <section className="text-center">
      <a
        href="/fullscreen"
        className="rounded-full bg-gray-900/70 py-2.5 px-5 text-gray-400 hover:bg-gray-800/70"
        title="特斯拉全屏浏览模式"
      >
        全屏浏览
      </a>
    </section>
  ) : null;
};

export default FullScreen;
