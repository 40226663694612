/**
 * check url is valid
 * @param text
 * @returns
 */
export const isValidUrl = (text: string): boolean => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(text);
};

export const isHttp = (text: string): boolean => {
  if (text) {
    return (
      text.toLowerCase().startsWith('http://') ||
      text.toLowerCase().startsWith('https://')
    );
  }
  return false;
};
