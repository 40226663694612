import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getImageArchive } from '../resources/api-constants';
import { setDataImages } from '../store/actions/data';
import { State } from '../types';

const useImageArchive = () => {
  const dispatch = useDispatch();
  const images = useSelector((state: State) => state.data.images || []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getImageArchive();
        if (res.statusCode === 200) {
          dispatch(setDataImages(res.data.images));
        }
      } catch (err) {
        //
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (images && images[0]) {
      const body = document.body;
      const image = images[0];
      const imageURL =
        image.imageUrls.landscape.wallpaper ||
        image.imageUrls.landscape.ultraHighDef ||
        image.imageUrls.landscape.highDef;
      const bgURL = `https://www.bing.com${imageURL}`;
      body.style.backgroundColor = 'rgba(75,85,99,1)';
      body.style.backgroundImage = `url('${bgURL}')`;

      return () => {
        body.removeAttribute('style');
      };
    }
  }, [images]);
};

export default useImageArchive;
