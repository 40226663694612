import { v4 as uuidv4 } from 'uuid';
import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Dialog, Transition } from '@headlessui/react';
import { isValidUrl } from '../utils/validation';
import { addDataShortcut, editDataShortcut } from '../store/actions/data';
import { Shortcut, State } from '../types';
import { useSelector } from 'react-redux';

// prop-types
interface Props {
  shortcut?: Shortcut;
  isOpen: boolean;
  onClose(value?: boolean): void;
}

type FormData = {
  id: string;
  name: string;
  url: string;
};

const ShortcutForm = ({ shortcut, isOpen, onClose }: Props) => {
  const dispatch = useDispatch();
  const shortcuts = useSelector((state: State) =>
    Object.values(state.data.shortcuts || {}),
  );

  const {
    reset,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>();

  useEffect(() => {
    if (shortcut) {
      reset({
        id: shortcut.id,
        name: shortcut.name,
        url: shortcut.url,
      });
    }
  }, [reset, shortcut]);

  const closeModal = () => {
    onClose();
    reset();
  };

  const urlValidate = (value: string) => {
    return (
      isValidUrl(value) &&
      !(
        shortcut ? shortcuts.filter((s) => s.url !== shortcut.url) : shortcuts
      ).find((s) => s.url === value)
    );
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (shortcut) {
      dispatch(
        editDataShortcut({
          id: data.id,
          name: data.name.trim(),
          url: data.url.trim(),
          updatedAt: new Date().valueOf(),
        }),
      );
    } else {
      dispatch(
        addDataShortcut({
          id: uuidv4(),
          name: data.name.trim(),
          url: data.url.trim(),
          createdAt: new Date().valueOf(),
          updatedAt: new Date().valueOf(),
        }),
      );
    }
    closeModal();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="mt-20 flex items-center justify-center py-4 px-8 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-semibold leading-6 text-gray-900 dark:text-gray-300"
                >
                  Add shortcut
                </Dialog.Title>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input type="hidden" {...register('id')} />
                  <div className="mt-6">
                    <label
                      htmlFor="name"
                      className="block text-lg font-medium text-gray-500 dark:text-gray-400"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      autoFocus={true}
                      className="mt-1 w-full rounded-md border-gray-200 text-lg shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300"
                      {...register('name', {
                        required: true,
                        validate: (value) => value.trim() !== '',
                      })}
                    />
                  </div>
                  <div className="mt-6 mb-4">
                    <label
                      htmlFor="url"
                      className="block text-lg font-medium text-gray-500 dark:text-gray-400"
                    >
                      URL
                    </label>
                    <input
                      type="url"
                      id="url"
                      className="mt-1 w-full rounded-md border-gray-200 text-lg shadow-sm dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300"
                      {...register('url', {
                        required: true,
                        validate: urlValidate,
                      })}
                    />
                    <p className="mt-2 text-sm text-gray-600">
                      Please provide a valid url, begin with http:// or https://
                    </p>
                  </div>
                  <div className="flex flex-row-reverse py-3">
                    <button
                      type="submit"
                      disabled={!isValid}
                      className="ml-3 w-auto rounded-md border border-transparent bg-blue-600 px-4 py-2 text-lg font-medium text-white shadow-sm hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-60 dark:text-gray-300"
                    >
                      Done
                    </button>
                    <button
                      type="button"
                      className="ml-3 w-auto rounded-md border border-gray-300 bg-white px-4 py-2 text-lg font-medium text-gray-700 shadow-sm hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShortcutForm;
