import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import { Shortcut } from '../types';
import { removeDataShortcut } from '../store/actions/data';
import { useDispatch } from 'react-redux';
import ShortcutForm from './ShortcutForm';

// prop-types
interface Props {
  shortcut: Shortcut;
}

const ShortcutMenu = ({ shortcut }: Props) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Menu as="div" className="relative h-6">
        <Menu.Button className="text-gray-300 group-hover:text-gray-400 dark:text-gray-700 dark:group-hover:text-gray-900">
          <EllipsisHorizontalCircleIcon className="h-6 w-6" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-800 dark:bg-gray-900 dark:ring-gray-700">
            <div className="px-1 py-1">
              <Menu.Item>
                <button
                  onClick={() => setModalIsOpen(true)}
                  className="flex w-full items-center rounded-md px-4 py-2 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                >
                  Edit shortcut
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => dispatch(removeDataShortcut(shortcut))}
                  className="flex w-full items-center rounded-md px-4 py-2 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                >
                  Remove
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <ShortcutForm
        shortcut={shortcut}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default ShortcutMenu;
