import React, { useEffect } from 'react';
import { useAudio, useVideo } from 'react-use';

// prop-types
interface Props {
  show: boolean;
}

const FireVideo = ({ show = false }: Props) => {
  const [video, videoState, videoControls] = useVideo(
    <video
      playsInline
      loop
      className="h-full w-full object-cover"
      hidden={!show}
      src={
        process.env.PUBLIC_URL +
        '/static/media/Fireplace_GettyRR_98571085_1080.mp4'
      }
    />,
  );
  const [audio, audioState, audioControls] = useAudio(
    <audio
      loop
      hidden
      src={
        process.env.PUBLIC_URL + '/static/media/CozyFirepace_Getty_553682.mp3'
      }
    />,
  );

  useEffect(() => {
    if (show && videoState.paused) {
      videoControls.play();
    }
    if (show && audioState.paused) {
      audioControls.play();
    }
  }, [show, videoState, videoControls, audioState, audioControls]);

  useEffect(() => {
    if (!show && videoState.playing) {
      videoControls.pause();
    }
    if (!show && audioState.playing) {
      audioControls.pause();
    }
  }, [show, videoState, videoControls, audioState, audioControls]);

  return (
    <>
      {video}
      {audio}
    </>
  );
};

export default FireVideo;
