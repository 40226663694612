import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import parser from 'ua-parser-js';

// prop-types
interface Props {
  isOpen: boolean;
  onClose(value?: boolean): void;
}

const Browser = ({ isOpen, onClose }: Props) => {
  const navigator = window.navigator || {};
  const screen = window.screen || {};

  const data = {
    vendor: navigator.vendor,
    platform: navigator.platform,
    language: navigator.language,
    languages: navigator.languages,
    screen: {
      width: screen.width,
      height: screen.height,
      availWidth: screen.availWidth,
      availHeight: screen.availHeight,
      outerWidth: window.outerWidth,
      outerHeight: window.outerHeight,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    },
    maxTouchPoints: navigator.maxTouchPoints,
    hardwareConcurrency: navigator.hardwareConcurrency,
    webdriver: navigator.webdriver,
    cookieEnabled: navigator.cookieEnabled,
    doNotTrack: navigator.doNotTrack,
    pdfViewerEnabled: navigator.pdfViewerEnabled,
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="my-12 flex min-h-full items-center justify-center px-8 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-900">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-semibold leading-6 text-gray-900 dark:text-gray-400"
                >
                  Browser&apos;s details
                </Dialog.Title>
                <div
                  className="absolute top-2 right-2 cursor-pointer text-gray-400 hover:text-gray-500 dark:text-gray-700"
                  onClick={() => onClose()}
                >
                  <XCircleIcon className="h-6 w-6" />
                </div>
                <div className="mx-auto mt-6 max-w-5xl place-items-center">
                  <div className="mb-3 flex items-center justify-between rounded-md bg-gray-200 p-4 dark:bg-gray-800 dark:text-gray-400">
                    {navigator.userAgent}
                  </div>
                  <div className="mb-3 flex items-center justify-between rounded-md bg-gray-200 p-4 dark:bg-gray-800 dark:text-gray-400">
                    <pre className="overflow-auto">
                      <code>
                        {JSON.stringify(
                          parser(navigator.userAgent),
                          (key, value) => {
                            if (key === 'ua') {
                              return undefined;
                            } else {
                              return value;
                            }
                          },
                          2,
                        )}
                      </code>
                    </pre>
                  </div>
                  <div className="mb-3 flex items-center justify-between rounded-md bg-gray-200 p-4 dark:bg-gray-800 dark:text-gray-400">
                    <pre className="overflow-auto">
                      <code>{JSON.stringify(data, null, 2)}</code>
                    </pre>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Browser;
