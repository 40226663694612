import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { isValidUrl, isHttp } from '../utils/validation';
import { removeDataHistory, setDataHistory } from '../store/actions/data';
import { State } from '../types';

const AddressBar = () => {
  const dispatch = useDispatch();
  const historys = useSelector((state: State) => state.data.historys || []);
  const searchEngines = useSelector((state: State) =>
    Object.values(state.data.searchEngines || {}),
  );
  const [text, setText] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const filteredHistorys =
    text === ''
      ? historys
      : historys
          .filter((history) =>
            history
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(text.toLowerCase().replace(/\s+/g, '')),
          )
          .slice(0, 10);

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    redirectUrl(text);
  };

  const onRemove = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    history: string,
  ) => {
    e.stopPropagation();
    dispatch(removeDataHistory(history));
  };

  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      window.addEventListener('click', hide);
    }

    return () => {
      window.removeEventListener('click', hide);
    };
  }, [open]);

  const redirectUrl = (val: string) => {
    if (val && isValidUrl(val)) {
      dispatch(setDataHistory(val));
      if (isHttp(val)) {
        window.location.href = val;
      } else {
        window.location.href = `https://${val}`;
      }
    }
    if (val && !isValidUrl(val)) {
      dispatch(setDataHistory(val));
      let url = 'https://www.bing.com/search?q=%s';
      const searchEngine = searchEngines.find((engine) => engine.default);
      if (searchEngine) {
        url = searchEngine.url;
      }
      window.location.href = url
        .replace('%s', val)
        .replace('{inputEncoding}', document.characterSet);
    }
  };

  return (
    <section className="px-8 pb-9">
      <div
        className="relative mx-auto max-w-2xl xl:max-w-3xl"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <form className="relative w-full overflow-hidden" onSubmit={onSubmit}>
          <input
            type="text"
            defaultValue={text}
            placeholder="Search or type a URL"
            className={`group w-full rounded-full ${
              open && filteredHistorys.length
                ? 'rounded-t-2xl rounded-b-none'
                : ''
            } border-gray-300 bg-white px-6 py-3 pr-14 text-lg text-gray-700 shadow-sm focus:border-gray-300 focus:outline-0 focus:ring-0 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200 dark:focus:border-gray-700 xl:px-7 xl:py-4 xl:text-xl`}
            onChange={(e) => setText(e.target.value.trim())}
            onClick={() => {
              setOpen(true);
            }}
          />
          <button
            type="submit"
            className="absolute inset-y-0 right-2 w-auto px-3 text-blue-600 hover:text-blue-800 dark:text-gray-600 dark:hover:text-gray-500"
          >
            <MagnifyingGlassIcon className="h-6 w-6 xl:h-7 xl:w-7" />
          </button>
        </form>
        {open && filteredHistorys.length ? (
          <div className="absolute z-10 w-full overflow-hidden rounded-b-2xl border border-t-0 border-gray-300 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800">
            {filteredHistorys.map((history, index) => (
              <div
                key={index}
                className="flex cursor-pointer justify-between px-6 py-3 text-gray-500 hover:bg-gray-100 hover:text-gray-600 dark:hover:bg-gray-900 dark:hover:text-gray-400"
                onClick={() => {
                  redirectUrl(history);
                }}
              >
                <div>{history}</div>
                <button
                  className="text-gray-400 hover:text-gray-500"
                  onClick={(e) => onRemove(e, history)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default AddressBar;
