import React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../types';
import AddShortcut from './AddShortcut';
import Tile from './Tile';

const Tiles = () => {
  const shortcuts = useSelector((state: State) =>
    Object.values(state.data.shortcuts || {}).sort(
      (a, b) => (a.createdAt || 0) - (b.createdAt || 0),
    ),
  );

  return (
    <section className="m-auto mb-10 flex max-w-6xl flex-row flex-wrap justify-center gap-4">
      {shortcuts.map((shortcut, index) => (
        <Tile key={index} shortcut={shortcut} />
      ))}
      <AddShortcut />
    </section>
  );
};

export default Tiles;
