import React from 'react';
import clsx from 'clsx';
import Snowfall from 'react-snowfall';

// prop-types
interface Props {
  show: boolean;
}

const SnowfallFrame = ({ show }: Props) => {
  return (
    <div
      className={clsx(
        'h-full w-full opacity-0 transition-opacity duration-1000 ease-out',
        {
          'opacity-100 ease-in': show,
        },
      )}
    >
      {show && (
        <Snowfall
          snowflakeCount={500}
          speed={[0.2, 0.5]}
          wind={[0, 0.2]}
          radius={[0.5, 2.5]}
        />
      )}
    </div>
  );
};

export default SnowfallFrame;
