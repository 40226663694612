import { Image, ReduxAction, Shortcut } from '../../types';

export enum DATA_ACTIONS {
  ADD_SHORTCUT = 'dataActions/addShortcut',
  REMOVE_SHORTCUT = 'dataActions/removeShortcut',
  EDIT_SHORTCUT = 'dataActions/editShortcut',
  SET_HISTORY = 'dataActions/setHistory',
  REMOVE_HISTORY = 'dataActions/removeHistory',
  SET_IMAGES = 'dataActions/setImages',
  UPDATE_DEFAULTSEARCHENGINE = 'dataActions/updateDefaultSearchEngine',
}

export const setDataHistory: ReduxAction<string> = (history: string) => {
  return {
    type: DATA_ACTIONS.SET_HISTORY,
    payload: history,
  };
};

export const removeDataHistory: ReduxAction<string> = (history: string) => {
  return {
    type: DATA_ACTIONS.REMOVE_HISTORY,
    payload: history,
  };
};

export const addDataShortcut: ReduxAction<Shortcut> = (shortcut: Shortcut) => {
  return {
    type: DATA_ACTIONS.ADD_SHORTCUT,
    payload: shortcut,
  };
};

export const removeDataShortcut: ReduxAction<Shortcut> = (
  shortcut: Shortcut,
) => {
  return {
    type: DATA_ACTIONS.REMOVE_SHORTCUT,
    payload: shortcut,
  };
};

export const editDataShortcut: ReduxAction<Shortcut> = (shortcut: Shortcut) => {
  return {
    type: DATA_ACTIONS.EDIT_SHORTCUT,
    payload: shortcut,
  };
};

export const setDataImages: ReduxAction<Image[]> = (images: Image[]) => {
  return {
    type: DATA_ACTIONS.SET_IMAGES,
    payload: images,
  };
};

export const updateDefaultSearchEngine: ReduxAction<string> = (
  name: string,
) => {
  return {
    type: DATA_ACTIONS.UPDATE_DEFAULTSEARCHENGINE,
    payload: name,
  };
};
