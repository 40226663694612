import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Squares2X2Icon } from '@heroicons/react/24/outline';
import Cards from './Cards';

const Launcher = () => {
  return (
    <Popover className="relative h-10 xl:h-10">
      <Popover.Button className="text-gray-400 outline-none hover:text-gray-500 dark:text-gray-700 dark:hover:text-gray-600">
        <Squares2X2Icon
          className="h-9 w-9 xl:h-10 xl:w-10"
          aria-hidden="true"
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-0 z-20 mt-2 w-80 transform md:w-[28rem]">
          <div className="overflow-hidden rounded-md bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-900 dark:ring-gray-700">
            <Cards />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Launcher;
