import React, { useEffect } from 'react';
import { useVideo } from 'react-use';

// prop-types
interface Props {
  show: boolean;
}

const AuroraVideo = ({ show = false }: Props) => {
  const [video, videoState, videoControls] = useVideo(
    <video
      playsInline
      loop
      className="h-full w-full object-cover"
      hidden={!show}
      src={
        process.env.PUBLIC_URL +
        '/static/media/1920_FinlandAurora_SS_13482083.mp4'
      }
    />,
  );

  useEffect(() => {
    if (show && videoState.paused) {
      videoControls.play();
    }
  }, [show, videoState, videoControls]);

  useEffect(() => {
    if (!show && videoState.playing) {
      videoControls.pause();
    }
  }, [show, videoState, videoControls]);

  return <>{video}</>;
};

export default AuroraVideo;
