import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';

const NotFoundPage = () => {
  const navigate = useNavigate();

  /**
   * Call this function to redirect the user to the homepage.
   */
  const redirectToHomePage = () => {
    navigate(ROUTES.HOMEPAGE_ROUTE);
  };

  return (
    <div className="grid h-screen place-content-center">
      <div className="text-center">
        <strong className="text-9xl font-black text-gray-200 dark:text-gray-800">
          404
        </strong>
        <h1 className="text-2xl font-bold tracking-tight text-gray-800 dark:text-gray-300">
          Uh-oh!
        </h1>
        <p className="mt-4 text-gray-500">We can&apos;t find that page.</p>
        <a
          href=""
          onClick={() => redirectToHomePage()}
          className="mt-6 inline-block rounded-md bg-blue-600 px-5 py-3 text-sm font-medium text-white shadow-sm hover:bg-blue-700"
        >
          Go Back Home
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
