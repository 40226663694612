import React from 'react';
import Launcher from './Launcher';
import Settings from './Settings';

const Header = () => {
  return (
    <header className="flex justify-between px-8 py-12 md:py-8">
      <Launcher />
      <Settings />
    </header>
  );
};

export default Header;
