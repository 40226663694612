import axios from 'axios';
import { Images } from '../types';
import language from '../utils/language';

export const getImageArchive = async () => {
  const lang = language();
  const res = await axios.get<Images>(
    `https://s3xy.app/images?format=json&ensearch=1&FORM=BEHPTB&mkt=${lang}`,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
  return res.data;
};
