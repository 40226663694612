import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import ShortcutForm from './ShortcutForm';

const AddShortcut = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className="flex h-32 w-32 flex-col items-center justify-center rounded-md bg-gray-100 text-gray-300 shadow-sm hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700"
        onClick={() => setModalIsOpen(true)}
      >
        <div className="my-2 flex h-16 w-16 items-center justify-center rounded-full bg-gray-300 text-gray-500 dark:bg-gray-900">
          <PlusIcon className="h-8 w-8" />
        </div>
        <div className="w-full truncate px-3 py-1 text-center text-gray-600 dark:text-gray-400">
          Add shortcut
        </div>
      </button>
      <ShortcutForm
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default AddShortcut;
