import React from 'react';
import clsx from 'clsx';

// prop-types
interface Props {
  show: boolean;
}

const LightsFrame = ({ show }: Props) => {
  return (
    <div
      className={clsx(
        'h-full w-full opacity-0 transition-opacity duration-1000 ease-out',
        {
          'opacity-100 ease-in': show,
        },
      )}
    >
      <div className="bg-lights absolute -top-28 left-0 h-28 w-[500%] origin-[0_7rem] rotate-90 bg-contain bg-center bg-repeat-x"></div>
      <div className="bg-lights absolute top-full left-[calc(100%_-_7rem)] h-28 w-[500%] origin-[0_0] -rotate-90 bg-contain bg-center bg-repeat-x"></div>
      <div className="bg-lights absolute top-0 h-28 w-[500%] rotate-180 bg-contain bg-bottom bg-repeat-x"></div>
      <div className="bg-lights absolute bottom-0 h-28 w-[500%] bg-contain bg-bottom bg-repeat-x"></div>
    </div>
  );
};

export default LightsFrame;
