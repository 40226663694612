import React from 'react';
import Card from './Card';

const Cards = () => {
  const data = [
    { name: '2048', icon: 'games/2048/screenshot.png', url: 'games/2048' },
    {
      name: 'TAP TAP TAP',
      icon: 'games/taptaptap/screenshot.png',
      url: 'games/taptaptap',
    },
    {
      name: 'Let&apos;s Surf',
      icon: 'games/surf/screenshot.png',
      url: 'games/surf',
    },
    {
      name: 'Virtual Piano',
      icon: 'games/virtual-piano/screenshot.png',
      url: 'games/virtual-piano',
    },
    {
      name: 'Fruit Ninja',
      icon: 'games/fruit-ninja/screenshot.png',
      url: 'games/fruit-ninja',
    },
    {
      name: 'Hextris Lite',
      icon: 'games/hextris-lite/screenshot.png',
      url: 'games/hextris-lite',
    },
    {
      name: 'Tower Building',
      icon: 'games/tower/screenshot.png',
      url: 'games/tower',
    },
    {
      name: 'Scroll!',
      icon: 'games/scroll/screenshot.png',
      url: 'games/scroll',
    },
  ];

  return (
    <div className="mx-auto grid grid-cols-2 place-items-center gap-4">
      {data.map(({ name, icon, url }) => (
        <Card key={name} name={name} icon={icon} url={url} />
      ))}
    </div>
  );
};

export default Cards;
