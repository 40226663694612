import { Image, Shortcut, ReducerData, ReduxActionData } from '../../types';
import { DATA_ACTIONS } from '../actions/data';
import initialState from './initialState';

const ACTION_HANDLERS: {
  [key: string]: (
    previousState: ReducerData,
    action: ReduxActionData<any>,
  ) => ReducerData;
} = {
  [DATA_ACTIONS.SET_HISTORY]: (
    previousState: ReducerData,
    action: ReduxActionData<string>,
  ) => {
    const previouHistorys = [...previousState.historys];
    const history = action.payload;
    const historys = history
      ? [history, ...previouHistorys.filter((h) => h !== history)]
      : previouHistorys;

    return {
      ...previousState,
      historys: historys.slice(0, 100),
    };
  },
  [DATA_ACTIONS.REMOVE_HISTORY]: (
    previousState: ReducerData,
    action: ReduxActionData<string>,
  ) => {
    const previouHistorys = [...previousState.historys];
    const history = action.payload;
    const historys = history
      ? previouHistorys.filter((h) => h !== history)
      : previouHistorys;

    return {
      ...previousState,
      historys: historys,
    };
  },
  [DATA_ACTIONS.ADD_SHORTCUT]: (
    previousState: ReducerData,
    action: ReduxActionData<Shortcut>,
  ) => {
    const previouShortcuts = previousState.shortcuts;
    const shortcut = action.payload;

    return {
      ...previousState,
      shortcuts: {
        ...previouShortcuts,
        ...(shortcut ? { [shortcut.id]: shortcut } : undefined),
      },
    };
  },
  [DATA_ACTIONS.REMOVE_SHORTCUT]: (
    previousState: ReducerData,
    action: ReduxActionData<Shortcut>,
  ) => {
    const previouShortcuts = { ...previousState.shortcuts };
    const shortcut = action.payload;

    if (shortcut && previouShortcuts[shortcut.id]) {
      delete previouShortcuts[shortcut.id];
    }

    return {
      ...previousState,
      shortcuts: previouShortcuts,
    };
  },
  [DATA_ACTIONS.EDIT_SHORTCUT]: (
    previousState: ReducerData,
    action: ReduxActionData<Shortcut>,
  ) => {
    const previouShortcuts = { ...previousState.shortcuts };
    const shortcut = action.payload;

    if (shortcut && previouShortcuts[shortcut.id]) {
      previouShortcuts[shortcut.id] = {
        ...previouShortcuts[shortcut.id],
        name: shortcut.name,
        url: shortcut.url,
        updatedAt: shortcut.updatedAt,
      };
    }

    return {
      ...previousState,
      shortcuts: previouShortcuts,
    };
  },
  [DATA_ACTIONS.SET_IMAGES]: (
    previousState: ReducerData,
    action: ReduxActionData<Image[]>,
  ) => {
    return {
      ...previousState,
      images: action.payload || [],
    };
  },
  [DATA_ACTIONS.UPDATE_DEFAULTSEARCHENGINE]: (
    previousState: ReducerData,
    action: ReduxActionData<string>,
  ) => {
    const previouSearchEngines = { ...previousState.searchEngines };
    const searchEngineName = action.payload;
    for (const searchEngine in previouSearchEngines) {
      previouSearchEngines[searchEngine].default = false;
    }
    return {
      ...previousState,
      searchEngines: {
        ...previouSearchEngines,
        [searchEngineName]: {
          ...previouSearchEngines[searchEngineName],
          default: true,
        },
      },
    };
  },
};

const dataState: (
  state: ReducerData,
  action: ReduxActionData<any>,
) => ReducerData = (state = initialState, action: ReduxActionData<any>) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default dataState;
