import { ReducerData } from '../../types';

const initialState: ReducerData = {
  images: [],
  historys: [],
  searchEngines: {
    Google: {
      name: 'Google',
      url: 'https://www.google.com/search?q=%s&ie={inputEncoding}',
      default: false,
    },
    Bing: {
      name: 'Bing',
      url: 'https://www.bing.com/search?q=%s',
      default: true,
    },
    百度: {
      name: '百度',
      url: 'https://www.baidu.com/#ie={inputEncoding}&wd=%s',
      default: false,
    },
  },
  shortcuts: {},
};

export default initialState;
