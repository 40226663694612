import React, { useEffect } from 'react';
import { useAudio } from 'react-use';

// prop-types
interface Props {
  show: boolean;
}

const BellsAudio = ({ show = false }: Props) => {
  const [audio, audioState, audioControls] = useAudio(
    <audio
      loop
      hidden
      src={process.env.PUBLIC_URL + '/static/media/xmas_bells.mp3'}
    />,
  );

  useEffect(() => {
    if (show && audioState.paused) {
      audioControls.play();
    }
  }, [show, audioState, audioControls]);

  useEffect(() => {
    if (!show && audioState.playing) {
      audioControls.pause();
    }
  }, [show, audioState, audioControls]);

  return <>{audio}</>;
};

export default BellsAudio;
