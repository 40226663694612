import React from 'react';
import { Shortcut } from '../types';
import ShortcutMenu from './ShortcutMenu';

// prop-types
interface Props {
  shortcut: Shortcut;
  hideMenu?: boolean;
}

const Tile = ({ shortcut, hideMenu }: Props) => {
  return (
    <a
      href={shortcut.url}
      className="group relative flex h-32 w-32 flex-col items-center justify-center rounded-md bg-gray-100 shadow-sm hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700"
    >
      <img
        className="my-2 h-16 w-16 rounded-full bg-gray-300 object-none dark:bg-gray-900"
        src={`https://s3xy.app/favicons?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${shortcut.url}&size=64`}
      />
      <div
        className="absolute right-1 top-1"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        {!hideMenu && <ShortcutMenu shortcut={shortcut} />}
      </div>
      <div
        title={shortcut.name}
        className="w-full truncate px-4 py-1 text-center text-gray-600 dark:text-gray-400"
      >
        {shortcut.name}
      </div>
    </a>
  );
};

export default Tile;
