import React, { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { State } from '../types';
import Browser from './Browser';
import About from './About';
import { updateDefaultSearchEngine } from '../store/actions/data';

const Settings = () => {
  const dispatch = useDispatch();
  const [aboutIsOpen, setAboutIsOpen] = useState(false);
  const [browserIsOpen, setBrowserIsOpen] = useState(false);
  const searchEngines = useSelector((state: State) =>
    Object.values(state.data.searchEngines || {}),
  );

  const downloadImage = () => {
    const styles = window.getComputedStyle(document.body);
    const image = styles.backgroundImage;
    const url = image.slice(5, -2);
    saveAs(url, Date.now() + '.jpg');
  };

  return (
    <>
      <Menu as="div" className="relative h-9 xl:h-10">
        <Menu.Button className="text-gray-400 hover:text-gray-500 dark:text-gray-700 dark:hover:text-gray-600">
          <Cog6ToothIcon
            className="h-9 w-9 xl:h-10 xl:w-10"
            aria-hidden="true"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-20 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-800 dark:bg-gray-900 dark:ring-gray-700">
            {searchEngines.length ? (
              <div className="px-1 py-1">
                <Menu.Item>
                  <div
                    className="w-full px-4 py-2 text-lg text-gray-500"
                    onClick={(e) => e.preventDefault()}
                  >
                    <label className="mb-2 block">Search Engine</label>
                    <select
                      className="w-full rounded-md border-gray-300 text-lg"
                      onChange={(e) => {
                        dispatch(updateDefaultSearchEngine(e.target.value));
                      }}
                      defaultValue={
                        searchEngines.find(
                          (searchEngine) => searchEngine.default,
                        )?.name
                      }
                    >
                      {searchEngines.map((searchEngine) => (
                        <option
                          key={searchEngine.name}
                          value={searchEngine.name}
                        >
                          {searchEngine.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Menu.Item>
              </div>
            ) : (
              <></>
            )}
            <div className="px-1 py-1">
              <Menu.Item>
                <button
                  onClick={downloadImage}
                  className="flex w-full items-center rounded-md px-4 py-2 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                >
                  Download Image
                </button>
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                <button
                  onClick={() => setAboutIsOpen(true)}
                  className="flex w-full items-center rounded-md px-4 py-2 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                >
                  About
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  onClick={() => setBrowserIsOpen(true)}
                  className="flex w-full items-center rounded-md px-4 py-2 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                >
                  Browser&apos;s details
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <About isOpen={aboutIsOpen} onClose={() => setAboutIsOpen(false)} />
      <Browser isOpen={browserIsOpen} onClose={() => setBrowserIsOpen(false)} />
    </>
  );
};

export default Settings;
