import React, { useCallback, useEffect, useState } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTimeoutFn } from 'react-use';
import { State } from '../types';
import * as Icons from './Icons';
import FireVideo from './FireVideo';
import AuroraVideo from './AuroraVideo';
import BellsAudio from './BellsAudio';
import LightsFrame from './LightsFrame';
import FrostFrame from './FrostFrame';
import SnowfallFrame from './SnowfallFrame';

const FloatingMenu = () => {
  const images = useSelector((state: State) => state.data.images || []);
  const [imageIndex, setImageIndex] = useState(0);
  const [menu, setMenu] = useState<{ [key: string]: boolean }>({});
  const [hide, setHide] = useState(false);

  const [isReady, cancel, reset] = useTimeoutFn(() => {
    setHide(true);
  }, 4000);

  const getImageURL = (index: number) => {
    const image = images[index];
    const imageURL =
      image.imageUrls.landscape.wallpaper ||
      image.imageUrls.landscape.ultraHighDef ||
      image.imageUrls.landscape.highDef;
    const bgURL = `https://www.bing.com${imageURL}`;
    return bgURL;
  };

  const onClick = (index: number) => {
    if (images.length > 0) {
      const body = document.body;
      const bgURL = getImageURL(index);
      body.style.backgroundImage = `url('${bgURL}')`;
      setImageIndex(index);
    }
  };

  const showMenu = useCallback(() => {
    setHide(false);
    reset();
  }, [reset]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  useEffect(() => {
    if (hide && isReady()) {
      window.addEventListener('click', showMenu);
      return () => {
        window.removeEventListener('click', showMenu);
      };
    }
  }, [hide, isReady, showMenu]);

  return (
    <>
      <aside
        className={clsx(
          'fixed bottom-6 right-1/2 z-50 m-auto flex translate-x-1/2 items-center justify-center transition-all duration-1000 ease-in',
          { 'invisible opacity-0 ease-out': hide },
        )}
        onMouseUp={reset}
        onMouseMove={cancel}
        onMouseOut={reset}
        onTouchMove={cancel}
        onTouchEnd={reset}
      >
        <div className="flex h-12 items-center justify-center rounded-md bg-gray-900/70 px-1">
          <button
            className={clsx(
              'ml-1 flex h-8 w-8 items-center justify-center rounded-md text-gray-200 hover:bg-gray-800/70',
              { 'bg-red-600 hover:bg-red-600': menu.screen },
            )}
            onClick={() => {
              setMenu({ ...menu, screen: !menu.screen });
              toggleFullScreen();
            }}
            title="Full screen mode"
          >
            {menu.screen ? (
              <ArrowsPointingInIcon className="h-5 w-5" />
            ) : (
              <ArrowsPointingOutIcon className="h-5 w-5" />
            )}
          </button>

          <button
            className={clsx(
              'ml-1 flex h-8 w-8 items-center justify-center rounded-md text-gray-200 hover:bg-gray-800/70',
              { 'bg-red-600 hover:bg-red-600': menu.snow },
            )}
            onClick={() => {
              setMenu({ ...menu, snow: !menu.snow });
            }}
            title="Watch the snow fall"
          >
            <Icons.SnowIcon className="h-5 w-5" />
          </button>
          <button
            className={clsx(
              'ml-1 flex h-8 w-8 items-center justify-center rounded-md text-gray-200 hover:bg-gray-800/70',
              { 'bg-red-600 hover:bg-red-600': menu.frost },
            )}
            onClick={() => {
              setMenu({ ...menu, frost: !menu.frost });
            }}
            title="Add a layer of frost"
          >
            <Icons.FrostIcon className="h-5 w-5" />
          </button>
          <button
            className={clsx(
              'ml-1 flex h-8 w-8 items-center justify-center rounded-md text-gray-200 hover:bg-gray-800/70',
              { 'bg-red-600 hover:bg-red-600': menu.lights },
            )}
            onClick={() => {
              setMenu({ ...menu, lights: !menu.lights });
            }}
            title="Flip on the lights"
          >
            <Icons.LightsIcon className="h-5 w-5" />
          </button>
          <button
            className={clsx(
              'ml-1 flex h-8 w-8 items-center justify-center rounded-md text-gray-200 hover:bg-gray-800/70',
              { 'bg-red-600 hover:bg-red-600': menu.fire },
            )}
            onClick={() => {
              setMenu({ ...menu, fire: !menu.fire, aurora: false });
            }}
            title="Fire up the yule log"
          >
            <Icons.YuleIcon className="h-5 w-5" />
          </button>
          <button
            className={clsx(
              'ml-1 flex h-8 w-8 items-center justify-center rounded-md text-gray-200 hover:bg-gray-800/70',
              { 'bg-red-600 hover:bg-red-600': menu.aurora },
            )}
            onClick={() => {
              setMenu({ ...menu, fire: false, aurora: !menu.aurora });
            }}
            title="See the northern lights"
          >
            <Icons.AuroraIcon className="h-5 w-5" />
          </button>
          <button
            className={clsx(
              'mx-1 flex h-8 w-8 items-center justify-center rounded-md text-gray-200 hover:bg-gray-800/70',
              { 'bg-red-600 hover:bg-red-600': menu.bells },
            )}
            onClick={() => {
              setMenu({ ...menu, bells: !menu.bells });
            }}
            title="Sleigh bells ring. Hear them?"
          >
            <Icons.JingleIcon className="h-5 w-5" />
          </button>
        </div>
        {images.length > 0 && (
          <>
            <button
              disabled={imageIndex === 0}
              className="ml-1 flex h-12 w-12 items-center justify-center rounded-l-md bg-gray-900/70 text-gray-200 hover:bg-gray-900/80 disabled:text-gray-400 disabled:hover:bg-gray-900/70"
              onClick={() => {
                setMenu({ ...menu, fire: false, aurora: false });
                onClick(imageIndex > 0 ? imageIndex - 1 : 0);
              }}
              title="Previous image"
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <button
              disabled={imageIndex === images.length - 1}
              className="flex h-12 w-12 items-center justify-center rounded-r-md bg-gray-900/70 text-gray-200 hover:bg-gray-900/80 disabled:text-gray-400 disabled:hover:bg-gray-900/70"
              onClick={() => {
                setMenu({ ...menu, fire: false, aurora: false });
                onClick(
                  imageIndex <= images.length - 1
                    ? imageIndex + 1
                    : images.length - 1,
                );
              }}
              title="Next image"
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </>
        )}
      </aside>
      <div
        className={clsx('fixed inset-0 h-full w-full', {
          'z-40 bg-gray-600 bg-cover bg-center bg-no-repeat': menu.screen,
        })}
        style={
          menu.screen
            ? { backgroundImage: `url(${getImageURL(imageIndex)})` }
            : {}
        }
      >
        <FireVideo show={menu.fire} />
        <AuroraVideo show={menu.aurora} />
        <BellsAudio show={menu.bells} />
        <SnowfallFrame show={menu.snow} />
        <FrostFrame show={menu.frost} />
        <LightsFrame show={menu.lights} />
      </div>
    </>
  );
};

export default FloatingMenu;
